import db from "../db";
import store from "../store";
import {firestoreAction} from "vuexfire";
import firebase from "@/firebase";

const state = {

};

const getters = {};

const actions = {

  async getRecords({ state }, params) {
    if (store.state.sourceForRecordLists !== 'firestore-query') {
      return [];
    }
    state[params.module] = [];
    const lastVisible = state[params.module + "_lastVisible"];
    const firstVisible = state[params.module + "_firstVisible"];
    const order = params.sortBy || "changedAt";
    const orderAsc = params.descending ? "desc" : "asc";
    const table =
      "tenants/" +
      store.state.tenantID +
      "/modules/" +
      params.module +
      "/records";
    let collection = db.collection(table);

    // filters
    params.filters.forEach((f) => {
      collection = collection.where(f[0], f[1], f[2]);
    });

    if (params.filter) {
      collection = collection.orderBy('name').startAt(params.filter).endAt(params.filter + '\uf8ff');
    } else {
      collection = collection.orderBy(order, orderAsc);
    }

    if (params.arrow >= 0) {
      collection = collection.limit(params.rowsPerPage);
      if (lastVisible && params.arrow > 0) {
        collection = collection.startAfter(lastVisible);
      }
    } else {
      collection = collection
        .endBefore(firstVisible)
        .limitToLast(params.rowsPerPage);
    }

    const result = await collection.get();
    state[params.module] = [];
    state[params.module + "_lastVisible"] = result.docs
      ? result.docs[result.docs.length - 1]
      : null;
    state[params.module + "_firstVisible"] = result.docs
      ? result.docs[0]
      : null;
    result.forEach((doc) => {
      const rec = doc.data();
      rec.id = doc.id;
      state[params.module].push(rec);
    });
    return result;
  },

  async queryRelatedFieldOptions({ state }, param) {
    const relatedModule = param.relatedModule;
    const query = param.query;
    //console.log(query);
    const table = "tenants/" + store.state.tenantID + "/modules/" + relatedModule + "/records";
    let collection = db.collection(table);
    query.sort((a, b) => { return a.order - b.order })
      .forEach((f) => {
        if (f.relatedTo) {
          if (f.relatedID) {
            collection = collection.where(
              new firebase.firestore.FieldPath(f.field, 'ID'), '==', f.relatedID);
          }
        } else {
          if (f.field) {
            collection = collection.where(f.field, '==', f.value);
          }
        }
      });
    const result = await collection.get();
    const options = [];
    result.forEach((doc) => {
      const rec = doc.data();
      rec.id = doc.id;
      options.push(rec);
    });
    //console.log(options);
    return options;
  },

  async querySubRecords({ state }, param) {
    const module = param.module;
    const subRecord = param.subRecord;
    const documentID = param.documentID;
    //console.log(query);
    const table = "tenants/" + store.state.tenantID + "/modules/" + module + "/records/"+ documentID + "/" + subRecord;
    //console.log(query);
    const collection = db.collection(table);
    const result = await collection.get();
    const options = [];
    result.forEach((doc) => {
      const rec = doc.data();
      options.push(rec);
    });
    //console.log(options);
    return options;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
