import { vuexfireMutations } from "vuexfire";
import store from "../store";
import firebase from "../firebase";

const SET_PROFILE = "SET_PROFILE";
const LOGOUT = "LOGOUT";

const state = {
  profile: {},
  loggedIn: false,
};

const getters = {
  profile: ({ profile }) => profile,
  loggedIn: ({ loggedIn }) => loggedIn,
};

const mutations = {
  [SET_PROFILE](state, profile) {
    state.loggedIn = true;
    state.profile = {
      name: "",
      picture: "",
      uid: profile.uid,
      email: profile.email,
    };
    store.commit("SET_USER", state.profile);
  },

  [LOGOUT](state) {
    state.loggedIn = false;
    state.profile = {};
    store.commit("SET_USER", state.profile);
  },

  ...vuexfireMutations,
};

const actions = {
  //async login(store) {
  // if user is already logged in return
  //if (store.state.loggedIn)
  //    return;

  //const provider = new firebase.auth.GoogleAuthProvider();
  //try {
  //await firebase.auth().signInWithPopup(provider);
  //} catch(error) {
  //console.log(error);
  //}
  // },

  async logout() {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
