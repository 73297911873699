import Vue from "vue";
import i18n from "./i18n";
import VueRouter from "vue-router";

import VueTimers from "vue-timers";
import money from 'v-money';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import * as numeral from 'numeral';

import router from "./router";
import store from "./store";
import "./auth";
import "./quasar";
import "./styles/main.scss";

Vue.use(VueRouter);
Vue.use(VueTimers);
Vue.use(money, {precision: 4});
Vue.use(vueNumeralFilterInstaller);

// switch between locales
numeral.locale('de');

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h("router-view"),
}).$mount("#app");
