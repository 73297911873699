import db from "../db";
import store from "../store";
import { firestoreAction } from "vuexfire";
import Vue from "vue";

const state = {

};

const getters = {};

const actions = {

// subscribe on dynamic modules
bindRecordLists: firestoreAction(({ state, bindFirestoreRef }) => {

    const table = "tenants/" + store.state.tenantID + "/modules/";
    const collection = db.collection(table);
    collection.get().then(result => {
      const dynamicModules = {};
      const lineModules = {};
      result.forEach((doc) => {
        const rec = doc.data();
        if (rec.fields) {
          if (rec.dynamic) {
            rec.id = doc.id;
            Vue.set(dynamicModules, rec.id, rec);
          } else {
            rec.id = doc.id;
            Vue.set(lineModules, rec.id, rec);
          }
        }
      });

      for (const module in dynamicModules) {
        Vue.set(state, module, []);
        bindFirestoreRef(module, db.collection(
          "tenants/" + store.state.tenantID + "/modules/" + module +
          "/records")).then(() => {
            const m = module;
            //console.log(m);
        });
      }
      store.commit("SET_DYN_MODULES", dynamicModules);
      store.commit("SET_LINE_MODULES", lineModules);
      store.commit("SET_ALL_MODULES", { ...dynamicModules, ...lineModules });

    });
  }),

  // get module data
  async getModuleData({ state }, moduleName) {
    const table = "tenants/" + store.state.tenantID + "/modules/";
    const doc = await db.collection(table).doc(moduleName).get();
    return doc.data();
  },

  async saveModuleData({ state }, data) {
    const table = "tenants/" + store.state.tenantID + "/modules/";
    const doc = await db.collection(table).doc(data.name).set(data.data);
    await store.dispatch("dynamic/bindRecordLists");
    return doc;
  },

  async deleteModuleData({ state }, moduleName) {
    const table = "tenants/" + store.state.tenantID + "/modules/";
    await db.collection(table).doc(moduleName).delete();
    await store.dispatch("dynamic/bindRecordLists");
  }


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
