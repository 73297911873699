import db from "../../db";
import store from "../../store";
import firebase from "../../firebase";
import {asyncFor, asyncForEach} from '@/common/helpers/async-for-each';

export class User {
  ID = "";
  name = "";
}

export class Fields {
  ID = "";
  obsolete = false;
  createdAt: Date = (firebase as any).firestore.Timestamp.now();
  changedAt: Date = (firebase as any).firestore.Timestamp.now();
  createdBy: User = new User();
  changedBy: User = new User();
  name = "";
  active = true;
}

export default class AbModel {
  module = "";
  ID = "";

  data = { ID: "" }; // main data
  // relations data
  relations = {};
  relationsBy = {};
  dynModuleData = {};
  dynamicFields = {};
  autoNumbers = {};
  dynamicSections = {};
  subFormField = '';
  subFormFieldData = {
    dynamic: true,
    section: {},
    sections: {},
    fields: {}
  };
  fireStore: any;
  constructor() {
    this.fireStore = (firebase as any).firestore;
  }

  fields(): Fields {
    const f = new Fields();
    return f;
  }

  dynFields() {
    return this.dynamicFields;
  }

  dynAutoNumbers() {
    return this.autoNumbers;
  }

  dynSections() {
    return this.dynamicSections;
  }

  dynModule() {
    return this.dynModuleData;
  }

  // module fields info
  defaultValues = {};
  disabledFields = {};
  hiddenFields = {};
  dropDownValues = {};

  tenant(module: any = null): string {
    const lmodule = module ? module : this.module;
    return "tenants/" + store.state.tenantID + "/modules/" + lmodule + "/records";
  }

  tenantForDeleted(module: any = null): string {
    const lmodule = module ? module : this.module;
    return "tenants/" + store.state.tenantID + "/modules/" + lmodule + "/deletedRecords";
  }

  set(data, callBack = (id) => {}) {
    return new Promise((resolve, reject) => {
      //data = this.convertToObject(data);
      this.removeReadOnlyFields(data);
      const newDoc = db.collection(this.tenant()).doc();
      data.ID = newDoc.id;
      this.setCreated(data);
      newDoc.set(data).then(() => {
        if (callBack) {
          callBack(data.ID);
        }
        resolve();
      }).catch((e) => {
        reject(e);
      });
    });
  }

  update(id, data, callBack = (id) => {}) {
    return new Promise((resolve, reject) => {
      //data = this.convertToObject(data);
      this.removeReadOnlyFields(data);
      this.setUpdated(data);
      const doc = db.collection(this.tenant()).doc(id);
      doc.update(data).then(() => {
        if (callBack) {
          callBack(data.ID);
        }
        resolve();
      }).catch((e) => {
        reject(e);
      });
    });
  }

  save(data, callBack = (id) => {}) {
    if (data.ID) {
      return this.update(data.ID, data, callBack);
    } else {
      return this.set(data, callBack);
    }
  }

  setCreated(data) {
    this.setUpdated(data);
    data.createdAt = this.fireStore.Timestamp.now();
    if (store.state.currentUser && store.state.currentUser.ID && store.state.currentUser.name) {
      data.createdBy = store.state.currentUser;
    } else {
      data.createdBy = this.convertJson(new User());
    }
  }

  setUpdated(data) {
    data.changedAt = this.fireStore.Timestamp.now();
    if (store.state.currentUser && store.state.currentUser.ID && store.state.currentUser.name) {
      data.changedBy = store.state.currentUser;
    } else {
      data.changedBy = this.convertJson(new User());
    }
  }

  async delete(id: string, module = "") {
    const lmodule = module ? module : this.module;
    const doc = db.collection(this.tenant(lmodule)).doc(id);
    if (store.state.softDelete) {
      const dataToSave = await doc.get();
      await db.collection(this.tenantForDeleted(lmodule)).doc(id).set(dataToSave.data());
    }
    await this.deleteSubRecords(id, lmodule);
    return doc.delete();
  }

  async deleteSubRecords(id, lmodule) {
    const subRecords = {};
    const doc = await this.getFieldsInfoDoc(lmodule);

    if (doc && doc.sections) {
      for (const sec in doc.sections) {
        if (doc.sections[sec].type === 'usageSection') {
          const subName = doc.sections[sec].subRecordField ? doc.sections[sec].subRecordField : sec;
          subRecords[subName] = {table: '', values: []};
        }
      }

      for (const subRecord in subRecords) {
        const table = this.tenant(lmodule) +  '/' + id + '/' + subRecord;
        const tableDeleted = this.tenantForDeleted(lmodule) +  '/' + id + '/' + subRecord;
        subRecords[subRecord].table = table;
        subRecords[subRecord].tableDeleted = tableDeleted;
        const collection = db.collection(table);
        const result = await collection.get();
        result.forEach((doc) => {
          const rec = doc.data();
          subRecords[subRecord].values.push(rec);
        });
      }
    }

    if (store.state.softDelete) {
      await asyncFor(subRecords, async (subRecord) => {
        await asyncForEach(subRecords[subRecord].values, async (f) => {
          const doc = db.collection(subRecords[subRecord].table).doc(f.ID);
          const dataToSave = await doc.get();
          await db.collection(subRecords[subRecord].tableDeleted).doc(f.ID).set(dataToSave.data());
        });
      });
    }

    await asyncFor(subRecords, async (subRecord) => {
      await asyncForEach(subRecords[subRecord].values, async (f) => {
        const doc = db.collection(subRecords[subRecord].table).doc(f.ID);
        await doc.delete();
      });
    });

  }

  async getByID(id: string, module: string) {
    const doc = await db.collection(this.tenant(module)).doc(id).get();
    return doc.data();
  }

  async subscribeDoc(id: string, module: string, sfunc: Function) {
    return await db.collection(this.tenant(module)).doc(id).onSnapshot(function(doc) {
      sfunc(doc.data());
    });
  }

  convertToObject(data) {
    const saveTs = {};

    for (const f in data) {
      if (data[f] && typeof data[f].toDate === "function") {
        saveTs[f] = data[f];
      }
    }

    data = JSON.parse(JSON.stringify(data));

    for (const f in saveTs) {
      data[f] = saveTs[f];
    }

    return data;
  }

  removeReadOnlyFields(data) {
    const afields = this.dynAutoNumbers();
    for (const af in afields) {
      if (data.hasOwnProperty(af)) {
        delete data[af];
      }
    }
  }

  convertJson(data) {
    data = JSON.parse(JSON.stringify(data));
    return data;
  }

  async load(id: string): Promise<void> {
    await this.getModuleFieldsInfo();
    if (id) {
      this.ID = id;
      //await this.getRelations(id);
    }
  }

  setDynModuleName(module) {
    this.module = module;
  }

  setSubFormField(subFormField) {
    this.subFormField = subFormField;
  }

  setSubFormFieldData(subFormFieldData) {
    this.subFormFieldData = subFormFieldData;
  }

  async getFieldsInfoDoc(module = '') {
    const lmodule = module ? module : this.module;
    const firePath = "tenants/" + store.state.tenantID + "/modules/";
    const result: any = await db.collection(firePath).doc(lmodule).get();
    const docModule = result.data();
    return docModule;
  }

  getDynamicSections(docModule) {
    return docModule.sections || {};
  }

  async getModuleFieldsInfo() {
    this.defaultValues = {};
    this.dropDownValues = {};
    this.autoNumbers = {};

    const doc = await this.getFieldsInfoDoc();

    if (doc) {

      if (doc.dropDownValues) {
        this.dropDownValues = doc.dropDownValues;
      }

      this.dynModuleData = doc;

      this.dynamicSections = doc.sections;

      if (doc.fields) {
        for (const field in doc.fields) {
          // dynamic fields

          const dField = { ...doc.fields[field] }
          // set related to field
          dField.relatedToByQuery = null;
          if (dField.type === 'relatedField' && dField.query) {
            dField.query.forEach((f) => {
              if (f.relatedTo) {
                dField.relatedToByQuery = f;
              }
            });
          }

          // auto numbers
          if (dField.type === 'autoNumber') {
            this.autoNumbers[field] = dField;
          }

          this.dynamicFields[field] = dField;

          // default values
          if (doc.fields[field].defaultValue) {
            this.defaultValues[field] = { 'defaultValue' : doc.fields[field].defaultValue};
          }

          // disabled fields
          if (doc.fields[field].hasOwnProperty('disabled')) {
            this.disabledFields[field] = doc.fields[field].disabled;
          }

          // hidden fields
          if (doc.fields[field].hasOwnProperty('hidden')) {
            this.hiddenFields[field] = doc.fields[field].hidden;
          }

        }
      }

    }
  }

  convertToTimeStamp(data, dfields) {
    for (const f in data) {
      if (
        data[f] &&
        dfields[f] &&
        dfields[f] instanceof Date &&
        !(typeof data[f].toDate === "function")
      ) {
        data[f] = (firebase as any).firestore.Timestamp.fromMillis(
          Date.parse(data[f])
        );
      }
    }
  }

}
