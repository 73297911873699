import db from "../db";
import store from "../store";
import Vue from "vue";

const state = {
  listSettings: {},
  planboardViewSettings: [],
  planboardDefaultViewID: '',
  userEmailSettings: null,
  userFormsSettings: {},
  tenantDefaultColumns: {},
};

const getters = {

};

const SET_USER_EMAIL_SETTINGS = "SET_USER_EMAIL_SETTINGS";
const SET_USER_FORMS_SETTINGS = "SET_USER_FORMS_SETTINGS";
const SET_TENANT_DEFAULT_COLUMNS = "SET_TENANT_DEFAULT_COLUMNS";

const mutations = {
  [SET_USER_EMAIL_SETTINGS](state, settings) {
    state.userEmailSettings = settings;
  },
  [SET_USER_FORMS_SETTINGS](state, settings) {
    state.userFormsSettings = settings;
  },
  [SET_TENANT_DEFAULT_COLUMNS](state, settings) {
    state.tenantDefaultColumns = settings;
  },
};

const actions = {
  async initSettings({ dispatch, commit }) {
    await dispatch('getEmailSettings');
    await dispatch('getFormListViews');
    const defColumns = await dispatch('getTenantDefaultColumns');
    commit("SET_TENANT_DEFAULT_COLUMNS", defColumns.data());
  },

  async getFormListViews({ commit }) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/form-settings/";
    const result = await db.collection(spath).get();
    const settings = {};
    result.forEach((doc) => {
      settings[doc.id] = doc.data();
    });
    commit("SET_USER_FORMS_SETTINGS", settings);
    return settings;
  },

  async saveFormListViews({ commit }, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/form-settings/";
    const doc = await db.collection(spath).doc(params.module).get();
    if (!doc.data()) {
      return await db.collection(spath).doc(params.module).set(params.data);
    } else {
      return await db.collection(spath).doc(params.module).update(params.data);
    }
  },

  async getTenantDefaultColumns({ getters }) {
    return db.collection("tenants/" + store.state.tenantID + "/settings/").doc('default-columns').get();
  },

  async setTenantDefaultColumns({ getters }, data) {
    return db.collection("tenants/" + store.state.tenantID + "/settings/").doc('default-columns').set(data);
  },

  cleanListSettings({ state }, module) {
    state.listSettings[module] = {};
  },

  async getListSettings({ state }, module) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const doc = await db.collection(spath).doc(module).get();
    if (!doc.data()) {
      await db.collection(spath).doc(module).set({});
    }

    const moduleDoc = doc.data() ?? {};
    moduleDoc.filters = [];
    moduleDoc.defaultFilter = null;
    moduleDoc.selectedFilter = null;

    const result = await db.collection(spath).doc(module).collection('lists').get();
    result.forEach((doc) => {
      const rec = doc.data();
      rec.ID = doc.id;
      moduleDoc.filters.push(rec);
      if (rec.default && !moduleDoc.defaultFilter) {
        moduleDoc.defaultFilter = rec;
      }
      if (rec.ID === moduleDoc.selectedFilterID) {
        moduleDoc.selectedFilter = rec;
      }
    });

    // create default filter
    if (!moduleDoc.defaultFilter) {
      const defColumns = await db.collection("tenants/" + store.state.tenantID + "/settings/").doc('default-columns').get();
      const defColumnsData = defColumns.data();
      const newDoc = db.collection(spath).doc(module).collection('lists').doc();
      moduleDoc.defaultFilter = {
        name: 'default',
        default: true,
        columns: defColumnsData && defColumnsData[module] ? defColumnsData[module] : [],
        filters: {},
        shared: false,
        pagination: {rowsPerPage : 10},
        ID: newDoc.id
      };
      moduleDoc.filters.push(moduleDoc.defaultFilter);
      await newDoc.set(moduleDoc.defaultFilter);
    }

    //console.log(state.listSettings, module, moduleDoc);
    Vue.set(state.listSettings, module, moduleDoc);
  },

  saveListFilter({ state }, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    if (params.filter.ID) {
      return db.collection(spath).doc(params.module).collection('lists').doc(params.filter.ID).update(params.filter);
    } else {
      return new Promise((resolve, reject) => {
        const newDoc = db.collection(spath).doc(params.module).collection('lists').doc();
        params.filter.ID = newDoc.id;
        newDoc.set(params.filter).then(() => {
          resolve({id: params.filter.ID});
        }).catch((e) => {
          reject(e);
        });
      });
    }
  },

  deleteListFilter({ state }, params) {
    if (params.filter.ID) {
      const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
      return db.collection(spath).doc(params.module).collection('lists').doc(params.filter.ID).delete();
    } else {
      alert("no filter.ID");
    }
  },

  async setListSettings({ state }, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    return await db.collection(spath).doc(params.module).update(params.data);
  },

  async getPlanboardViewSettings( {state} ) {
    const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const result = await db.collection(path).doc('planboard').collection('views').get();

    state.planboardViewSettings = [];
    result.forEach((doc) => {
      state.planboardViewSettings.push({
        ID: doc.id,
        name: doc.data().name,
        users: doc.data().users,
        period: doc.data().period
      })
    });
  },

  savePlanboardViewSettings({ state }, params) {
    return new Promise((resolve, reject) => {
      const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";

      if (params.id) {
        const ref = db.collection(path).doc('planboard').collection('views').doc(params.id)
        ref.update(params.view)
          .then(() => {resolve(ref.id);})
      } else {
        const ref = db.collection(path).doc('planboard').collection('views').doc()
        ref.set(params.view)
          .then(() => {resolve(ref.id);})
      }
    })
  },

  async deletePlanboardViewSettings({ state }, params) {
    const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    return await db.collection(path)
      .doc('planboard').collection('views').doc(params.id)
      .delete();
  },

  async getPlanboardDefaultViewID( {state} ) {
    const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const result = await db.collection(path).doc('planboard').get();
    state.planboardDefaultViewID = result.data().userViewID || ''
  },

  async savePlanboardDefaultViewID( {state}, params ) {
    const path = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    return await db.collection(path).doc('planboard').update(params);
  },

  // universal functions
  async getModuleSettings({ state }, module) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const doc = await db.collection(spath).doc(module).get();
    if (!doc.data()) {
      await db.collection(spath).doc(module).set({});
    }
    const moduleDoc = doc.data() ?? {};
    return moduleDoc;
  },

  async setModuleSettings({ state }, params) {
    const spath = "tenants/" + store.state.tenantID + "/users/" + store.state.userID + "/modules/";
    const doc = await db.collection(spath).doc(params.module).get();
    if (!doc.data()) {
      return await db.collection(spath).doc(params.module).set(params.data);
    } else {
      return await db.collection(spath).doc(params.module).update(params.data);
    }
  },

  async getEmailSettings({ commit }) {
    const query = await db.collection(`/tenants/${store.state.tenantID}/settings/email/addresses`).get();
    const result = [];
    query.forEach((doc) => {
      result.push(doc.data());
    });
    this.emailSettings = result;
    const sender = result.find((el) => el.allowedUsers.includes(store.state.currentUser.ID));
    const emailSender = sender;
    this.commit("settings/SET_USER_EMAIL_SETTINGS", emailSender);
    return emailSender;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
