const SelectModel = {
  customerID: "Klant", // CUS

  appointmentStatus: {
    // eslint-disable-next-line @typescript-eslint/camelcase
    toBePlanned: "Inplannen",
    toBeConfirmed: "Te bevestigen",
    planned: "Gepland",
    traveling: "Onderweg",
    inExecution: "In uitvoering",
    delayed: "Vertraagd",
    done: "Uitgevoerd",
  },

  workOrderPhase: {
    toBePlanned: "Inplannen",
  },

  timeTypes: {
    verlof: "Verlof",
    ziek: "Ziek",
    arbeid: "Arbeid",
    reis: "Reis",
    training: "Training",
  }
};

export default SelectModel;
