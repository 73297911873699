import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("./layouts/Default"),

      children: (function () {
        const modules = [
          {
            name: "TemplateConstructor",
            path: "template-constructor",
            list: import("./components/Unlayer/TemplateConstructor"),
          },
          {
            name: "nylas-web-hook",
            list: import("./components/Nylas/NylasWebHook"),
          },
          {
            name: "dynamicModules",
            path: "modules/:moduleName",
            list: import("./components/Forms/DynamicList"),
            form: import("./components/Forms/DynamicForm"),
            children: [
              {
                path: "/" + "modules/:moduleName" + "/modal/:action/:id?",
                name: "dynamicModules.formModal",
                component: () => import("./components/Forms/DynamicFormModal"),
                props: (route) => ({
                  ID: route.params.id,
                  action: route.params.action,
                  dynModuleName: route.params.moduleName,
                  isModalDynamicForm: true
                }),
              }
            ]
          },
          {
            path: "builder/:moduleName",
            name: "builder",
            list: import("./components/Builder/BuilderPage")
          }
        ];
        return modules
          .map((m) => {
            return {
              path: "/" + (m.path || m.name),
              name: m.name,
              component: () => m.list,
              props: (route) => ({
                dynModuleName: route.params.moduleName,
              }),
              children: m.children,
            };
          })
          .concat(
            modules.map((m) => {
              return {
                path: "/" + (m.path || m.name) + "/:action/:id?",
                name: m.name + ".form",
                component: () => m.form,
                props: (route) => ({
                  ID: route.params.id,
                  action: route.params.action,
                  dynModuleName: route.params.moduleName,
                }),
              };
            })
          )
          .concat([
            {
              path: "/settings/:userType?/:action?",
              name: "settings",
              component: () => import("./components/Settings/Settings"),
              props: (route) => ({
                userType: route.params.userType,
                action: route.params.action
              }),
            },
            {
              path: "/planboard",
              name: "planboard",
              component: () => import("./components/Planboard/PlanBoard"),
            },
            {
              path: "/planboard-map",
              name: "planboard-map",
              component: () => import("./components/Planboard/PlanBoardMap"),
            },
            {
              path: "/planboard_test",
              name: "planboard-test",
              component: () => import("./components/Planboard/Test"),
            }
          ]);
      })(),
    },
    {
      path: "/test",
      name: "test",
      component: () => import("./components/Test")
    }
  ],
});
