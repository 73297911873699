export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export async function asyncFor(obj, callback) {
  for (const prop in obj) {
    await callback(prop, obj[prop]);
  }
}
