import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";


import firebase from "./firebase";
import db from "./db";
import { storagePublic } from '@/storage';

import i18n from "./i18n";

import user from "./store/user";
import query from "./store/query";
import subscribe from "./store/subscribe";
import dynamic from "./store/dynamic";
import settings from "./store/settings";
import AbModel from "@/components/Models/AbModel";

const UPDATE_DATA = "UPDATE_DATA";
const SET_LOADING = "SET_LOADING";
const SET_INIT = "SET_INIT";
const SET_USER = "SET_USER";
const SET_TENANT = "SET_TENANT";
const SET_DYN_MODULES = "SET_DYN_MODULES";
const SET_LINE_MODULES = "SET_LINE_MODULES";
const SET_ALL_MODULES = "SET_ALL_MODULES";
const SET_LOADED = "SET_LOADED";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    awesome: true,

    userID: "",
    userProfile: {},
    tenantID: "",
    storage: firebase.storage,
    loading: true,
    initialization: false, // the tenant id loaded
    userSettings: {},
    userViewSettingsRaw: {},
    currentDate: new Date().toString("yyyy-MM-dd"),
    currentUser: {ID: "", name:""}, // current user from Users module by authID
    currentUserFull: {}, // full current user
    alertMessage: "none",
    holidaysRaw: [],

    moneyFormat : {
      decimal: ',',
      thousands: '.',
      prefix: "€ ",
      precision: 2,
      masked: false
    },
    percentageFormat : {
      decimal: ',',
      thousands: '.',
      precision: 2,
      suffix: '%',
      masked: false
    },
    softDelete: true, // use obsolete = true
    sourceForRecordLists: 'firestore-subscribe', // 'firestore-query', 'firestore-subscribe' and 'elastic-search'
    dynamicModules: {},
    lineModules: {},
    allModules: {},
    //dateMask: "YYYY-MM-DD",
    //dateTimeMask: "YYYY-MM-DD HH:mm",
    dateMask: "DD-MM-YYYY",
    dateTimeMask: "DD-MM-YYYY HH:mm",
    loadingControl: new Set(['user']),
  },

  getters: {
    language() {
      return i18n.locale;
    },
    locale() {
      return i18n.locale;
    },
    translate() {
      return i18n.messages[i18n.locale];
    },
    getCurrentLoggedInUser(state) {
      const currentUser = state.currentUser;
      if (currentUser && currentUser.ID && currentUser.name) {
        return currentUser;
      } else {
        return {ID: "", name:""};
      }
    },
    dynamicModulesData(state) {
      return state.dynamic;
    },
    systemVariables(state) {
      const vars = {
        '$currentUser': state.currentUser
      }
      return vars;
    }
  },

  mutations: {
    [SET_USER](state, profile) {
      state.userID = profile.uid;
      state.userProfile = profile;
      if (state.userID) {
        this.dispatch("setTenant");
      } else {
        this.dispatch("closeTenant");
      }
    },

    [SET_TENANT](state, tenantID) {
      state.tenantID = tenantID;
    },

    [SET_INIT](state, initialization) {
      state.initialization = initialization;
    },

    [SET_LOADING](state, loading) {
      state.loading = loading;
    },

    [SET_LOADED](state, loading) {
      state.loadingControl.delete(loading);
      if (!state.loadingControl.size) {
        state.loading = false;
        state.initialization = true;
      }
    },

    [SET_DYN_MODULES](state, dynamicModules) {
      state.dynamicModules = dynamicModules;
    },

    [SET_LINE_MODULES](state, lineModules) {
      state.lineModules = lineModules;
    },

    [SET_ALL_MODULES](state, allModules) {
      state.allModules = allModules;
    },

    [UPDATE_DATA](state, data) {
      state.techniciansList = data.techniciansList;
      state.counts = data.counts;
    },
    ...vuexfireMutations,
  },

  actions: {
    async setTenant({ state, commit, dispatch }) {
      const result = await db
        .collection("users")
        .where("authID", "==", state.userID)
        .get();
      const users = [];
      result.forEach((doc) => {
        users.push(doc.data());
      });
      if (users.length) {
        const tenantID = users[0].tenant;
        commit(SET_TENANT, tenantID);
        if (tenantID) {
          dispatch("initUserSettings");
          dispatch("settings/initSettings");
          dispatch("initStorage");
          dispatch("dynamic/bindRecordLists");
        } else {
          await user.actions.logout();
          commit(SET_INIT, false);
        }
      } else {
        await user.actions.logout();
        commit(SET_INIT, false);
      }
    },
    async closeTenant({ commit }) {
      commit(SET_INIT, false);
      // off all binds
    },
    setLanguage({ state }, language) {
      i18n.locale = language;
      db.collection(
        "tenants/" + state.tenantID + "/settings/" + "user-language" + "/users"
      )
        .doc(this.state.userID)
        .set({ language });
    },

    async initUserSettings({ state }) {

      if (state.userProfile.email) {
        const result = await db.collection("tenants/" + state.tenantID + "/modules/users/records").where("ID", "==", state.userID).get();
        const users = [];
        result.forEach((doc) => {
          users.push(doc.data());
        });
        if (users.length) {
          const curUser = users[0];
          this.state.currentUser = { ID: curUser.ID, name: curUser.name };
          this.state.currentUserFull = curUser;
        }
        this.commit(SET_LOADED, 'user');
      }

      const docL = db.collection(
        "tenants/" + state.tenantID + "/settings/" + "user-language" + "/users"
      );
      docL
        .doc(this.state.userID)
        .get()
        .then((snapshot) => {
          i18n.locale = snapshot.data() ? snapshot.data().language : "en-GB";
        });

      const docV = db.collection(
        "tenants/" + state.tenantID + "/settings/" + "user-view" + "/users"
      );
      docV
        .doc(this.state.userID)
        .get()
        .then((snapshot) => {
          this.state.userViewSettingsRaw = snapshot.data();
        });

      const docHolidays = db.collection(
        "tenants/" + state.tenantID + "/admin/"
      );
      docHolidays
        .doc("holidays")
        .get()
        .then((snapshot) => {
          this.state.holidaysRaw = snapshot.data();
        });

      const docSettings = db.collection(
        "tenants/" + state.tenantID + "/users/" + state.userID + "/settings"
      );
      docSettings
        .doc("emails")
        .get()
        .then((snapshot) => {
          this.state.userSettings.emails = snapshot.data();
        });
    },

    initStorage({ state }) {
      const docTenant = db.collection("tenants");
      docTenant
        .doc(state.tenantID)
        .get()
        .then((snapshot) => {
          if (snapshot.data()) {
            this.state.storage = firebase.app().storage(snapshot.data().bucket);
          }
        });
    },

    sendNotification({ state }, notification) {
      db.collection("tenants/" + state.tenantID + "/notifications")
        .add(notification)
        .then(() => {
          alert(` '${notification.message}' Notification was sent`);
        })
        .catch(() => {});
    },

    updateUserViewSettings: function ({ state }, fields) {
      const userFields = { ...fields };
      const docV = db.collection(
        "tenants/" + state.tenantID + "/settings/" + "user-view" + "/users"
      );
      docV
        .doc(this.state.userID)
        .set(userFields)
        .then(() => {
          docV.doc(this.state.userID).get().then((snapshot) => {
              this.state.userViewSettingsRaw = snapshot.data();
            });
        });
    },

    updateAssignMultiple: function ({ state }, assignMultiple) {
      const colection = db.collection("tenants/" + state.tenantID + "/modules/" + "appointments" + "/records");
      if (assignMultiple.id) {
        colection.doc(assignMultiple.id)
          .update(assignMultiple);
      } else {
        const newDoc = colection.doc();
        assignMultiple.ID = newDoc.id;
        newDoc.set(assignMultiple);
      }
    },

    getUserEmailsConfig({ state }) {
      return new Promise((resolve) => {
        return db
          .collection(
            "tenants/" +
              state.tenantID +
              "/users/" +
              state.userID +
              "/settings/"
          )
          .doc("emails")
          .get()
          .then((resp) => {
            if (resp.data() && typeof resp.data() !== "undefined") {
              resolve(resp.data());
            }
          })
          .catch(() => resolve({}));
      });
    },

    setUserEmailsConfig({ state }, emailsConfig) {
      return new Promise((resolve) => {
        return db
          .collection(
            "tenants/" +
              state.tenantID +
              "/users/" +
              state.userID +
              "/settings/"
          )
          .doc("emails")
          .set(emailsConfig)
          .then(() => resolve({}))
          .catch(() => resolve({}));
      });
    },

    resetAlertMessage({ state }) {
      state.alertMessage = "none";
    },

    showAlertMessage({ state }, msg) {
      state.alertMessage = msg;
    },

    copyWorkOrder({ state }, workOrder) {
      db.collection(
        "tenants/" + state.tenantID + "/modules/" + "workOrders" + "/records"
      ).add(workOrder);
    },

    uploadFileToStorage({ state }, file) {
      const docID = db.collection("tenants").doc().id;
      //console.log(file);
      return new Promise((resolve) => {
        const metadata = {
          createdBy: {ID: "", name: ""},
          createdAt: null,
          ID: docID,
          fileName: file.name,
          fileType: file.type,
          fileSize: null,
          downloadableURL: '',
          stage: file.stage || 0
        };
        new AbModel().setCreated(metadata);

        let childPath;
        if (file.onlyRef) {
          if (file.addTenant) {
            childPath = `/tenants/${state.tenantID}/${file.storageRef}${docID}`;
          } else {
            childPath = `/${file.storageRef}${docID}`;
          }
        } else {
          childPath = `/tenants/${state.tenantID}/modules/${file.storageRef}${docID}`;
        }

        let uploadTask;
        if (file.toPublic) {
           uploadTask = storagePublic.ref().child(childPath);
        } else {
           uploadTask = state.storage.ref().child(childPath);
        }

        if (file.base64PDF) {
          uploadTask = uploadTask.putString(file.base64PDF, 'base64', {contentType: file.type});
        } else {
          uploadTask = uploadTask.put(file, {});
        }

        uploadTask.on(
          "state_changed",
          (sp) => {
            //TODO show procents
            //console.log(file);
            if (file.procentShowCallBack) {
              file.procentShowCallBack(sp);
            }
          },
          null,
          () => {
            uploadTask.snapshot.ref.getMetadata().then((spMetadata) => {
              metadata.fileSize = spMetadata.size;

              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                metadata.downloadableURL = downloadURL;
                return resolve(metadata);
              });

            });
          }
        );
      });
    },

    deleteFileFromStorage({ state }, filePath) {
      return new Promise((resolve) => {
        state.storage
          .ref()
          .child(`/tenants/${state.tenantID}/modules/${filePath}`)
          .delete()
          .then(() => {
            return resolve();
          })
          .catch(() => {
            return resolve();
          });
      });
    },
  },

  modules: {
    user,
    query,
    subscribe,
    dynamic,
    settings,
  },
});

export default store;
