import db from "../db";
import store from "../store";
import { firestoreAction } from "vuexfire";
import SelectModel from "../components/Models/SelectModel";
import firebase from "../firebase";
import { date } from "quasar";

const state = {
  loading: 0, // subscribe is in the process

  filtersRaw: {},
  appointmentsT: [],
  ordersT: [],
  appointmentsR: [],
  ordersR: [],
  appointmentsM: [],
  ordersM: [],

};

const getters = {
  appointmentsTasks(state) {
    let apps;
    apps = state.appointmentsT;
    apps = apps.map((a) => {
      if (a && a.workOrder && a.workOrder.ID && !a.wo) {
        a.wo = state.ordersT.find((w) => w.id === a.workOrder.ID);
        // disable the event links as they don't make much sense at this moment, we need to rethink this functionality.
        // if (a.wo && a.wo.name) {
        //   a.container = Number(a.wo.name.substring(1,3) + a.wo.name.substring(4));
        // }
      }
      return a;
    });
    apps = apps.filter((a) => {
      return a.wo;
    });
    return apps;
  },
  appointmentsOrders() {
    let apps;
    apps = state.appointmentsR;
    apps = apps.map((a) => {
      if (a.workOrder && a.workOrder.ID && !a.wo) {
        a.wo = state.ordersR.find((w) => w.id === a.workOrder.ID);
        if (
          a.wo &&
          a.wo.dateTimeOfNotification &&
          typeof a.wo.dateTimeOfNotification.toDate === "function"
        ) {
          a.wo.dateTimeOfNotification = date.formatDate(
            a.wo.dateTimeOfNotification.toDate(),
            "YYYY-MM-DD HH:mm"
          );
        }
      }
      return a;
    });
    apps = apps.filter((a) => {
      return a.wo;
    });
    return apps;
  },
  appointmentsMapTasks(state) {
    const apps = state.appointmentsM;
    apps.map((a) => {
      if (a && a.workOrder && a.workOrder.ID && !a.wo) {
        a.wo = state.ordersM.find((w) => w.id === a.workOrder.ID);
      }
    });
    return apps;
  },
};

const actions = {
  bindAppointments: firestoreAction(({ state, bindFirestoreRef }) => {
    const filterTaskStart =
      state.filtersRaw.tasks &&
      state.filtersRaw.tasks &&
      state.filtersRaw.tasks.period &&
      state.filtersRaw.tasks.period.start
        ? firebase.firestore.Timestamp.fromDate(
            new Date(state.filtersRaw.tasks.period.start || "1970-01-01")
          )
        : firebase.firestore.Timestamp.fromDate(new Date(1970, 1));
    const filterTaskEnd =
      state.filtersRaw.tasks &&
      state.filtersRaw.tasks &&
      state.filtersRaw.tasks.period &&
      state.filtersRaw.tasks.period.end
        ? firebase.firestore.Timestamp.fromDate(
            new Date(state.filtersRaw.tasks.period.end || "2970-01-01")
          )
        : firebase.firestore.Timestamp.fromDate(new Date(2970, 1));

    let appointmentsT = db.collection(
      "tenants/" +
        store.state.tenantID +
        "/modules/" +
        "appointments" +
        "/records"
    );
    // TODO filter by date
    const ordersT = db.collection(
      "tenants/" +
        store.state.tenantID +
        "/modules/" +
        "workOrders" +
        "/records"
    );
    appointmentsT = appointmentsT
      .where("status", "in", [
        SelectModel.appointmentStatus.toBeConfirmed,
        SelectModel.appointmentStatus.planned,
        SelectModel.appointmentStatus.traveling,
        SelectModel.appointmentStatus.inExecution,
        SelectModel.appointmentStatus.delayed,
        SelectModel.appointmentStatus.done,
      ])
      .orderBy("plannedStartDateTime")
      //.where("plannedStartDateTime", ">=", filterTaskStart)
      //.where("plannedStartDateTime", "<=", filterTaskEnd);
    bindFirestoreRef("appointmentsT", appointmentsT).then(() => {

    });
    bindFirestoreRef("ordersT", ordersT).then(() => {

    });

    // orders
    const appointmentsR = db.collection("tenants/" + store.state.tenantID + "/modules/" + "appointments" + "/records")
      .where("status", "==", SelectModel.appointmentStatus.toBePlanned);
    const ordersR = db.collection("tenants/" + store.state.tenantID + "/modules/" + "workOrders" + "/records");
    bindFirestoreRef("appointmentsR", appointmentsR).then(() => {});
    bindFirestoreRef("ordersR", ordersR).then(() => {});
  }),

  bindAppointmentsMap: firestoreAction(({ state, bindFirestoreRef }) => {

    const finalDateDays =
      state.filtersRaw &&
      state.filtersRaw.mapTasks &&
      state.filtersRaw.mapTasks.filters &&
      state.filtersRaw.mapTasks.filters.finalDate
        ? state.filtersRaw.mapTasks.filters.finalDate
        : 100;
    const finalDatePlus = firebase.firestore.Timestamp.fromDate(
      date.addToDate(new Date(), { days: finalDateDays })
    );
    const finalDateMinus = firebase.firestore.Timestamp.fromDate(
      date.subtractFromDate(new Date(), { days: finalDateDays })
    );

    let appointmentsM = db.collection(
      "tenants/" +
        store.state.tenantID +
        "/modules/" +
        "appointments" +
        "/records"
    );
    appointmentsM = appointmentsM
      .where("status", "in", [
        SelectModel.appointmentStatus.toBeConfirmed,
        SelectModel.appointmentStatus.planned,
        SelectModel.appointmentStatus.traveling,
        SelectModel.appointmentStatus.inExecution,
        SelectModel.appointmentStatus.delayed,
        SelectModel.appointmentStatus.done,
      ]);
      //.where("plannedEndDateTime", "<", finalDatePlus)
      //.where("plannedEndDateTime", ">", finalDateMinus);
    const ordersM = db.collection(
      "tenants/" +
        store.state.tenantID +
        "/modules/" +
        "workOrders" +
        "/records"
    );
    bindFirestoreRef("appointmentsM", appointmentsM).then(() => {

    });
    bindFirestoreRef("ordersM", ordersM).then(() => {

    });
  }),

  unbindAppointments: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("appointmentsT");
    unbindFirestoreRef("ordersT");
    unbindFirestoreRef("appointmentsR");
    unbindFirestoreRef("ordersR");
  }),

  unbindAppointmentsMap: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("appointmentsM");
    unbindFirestoreRef("ordersM");
  }),

  initPlanBoardFilters({ state, dispatch }) {
    const doc = db.collection(
      "tenants/" +
        store.state.tenantID +
        "/settings/" +
        "user-filters" +
        "/users"
    );
    doc
      .doc(store.state.userID)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          state.filtersRaw = snapshot.data();
          if (!state.filtersRaw.tasks) state.filtersRaw.tasks = {};
          if (!state.filtersRaw.tasks.period)
            state.filtersRaw.tasks.period = {};
          if (!state.filtersRaw.orders) state.filtersRaw.orders = {};
          if (!state.filtersRaw.orders.period)
            state.filtersRaw.orders.period = {};
          if (!state.filtersRaw.mapTasks) state.filtersRaw.mapTasks = {};
          if (!state.filtersRaw.mapTasks.period)
            state.filtersRaw.mapTasks.period = {};
        }
        dispatch("bindAppointments");
      });
  },
  initPlanBoardFiltersMap({ state, dispatch }) {
    const doc = db.collection(
      "tenants/" +
        store.state.tenantID +
        "/settings/" +
        "user-filters" +
        "/users"
    );
    doc
      .doc(store.state.userID)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          state.filtersRaw = snapshot.data();
          if (!state.filtersRaw.tasks) state.filtersRaw.tasks = {};
          if (!state.filtersRaw.tasks.period)
            state.filtersRaw.tasks.period = {};
          if (!state.filtersRaw.orders) state.filtersRaw.orders = {};
          if (!state.filtersRaw.orders.period)
            state.filtersRaw.orders.period = {};
          if (!state.filtersRaw.mapTasks) state.filtersRaw.mapTasks = {};
          if (!state.filtersRaw.mapTasks.period)
            state.filtersRaw.mapTasks.period = {};
        }
        dispatch("bindAppointmentsMap");
      });
  },

  updatePlanBoardFilters({ state, dispatch }, filters = {}) {
    if (
      !state.filtersRaw.tasks &&
      !state.filtersRaw.orders &&
      !state.filtersRaw.mapTasks
    ) {
      state.filtersRaw = { ...state.filtersRaw, ...filters };
    }
    if (state.filtersRaw.tasks && filters.tasks) {
      state.filtersRaw.tasks = { ...state.filtersRaw.tasks, ...filters.tasks };
    }
    if (state.filtersRaw.orders && filters.orders) {
      state.filtersRaw.orders = {
        ...state.filtersRaw.orders,
        ...filters.orders,
      };
    }
    if (state.filtersRaw.mapTasks && filters.mapTasks) {
      state.filtersRaw.mapTasks = {
        ...state.filtersRaw.mapTasks,
        ...filters.mapTasks,
      };
    }
    const doc = db.collection(
      "tenants/" +
        store.state.tenantID +
        "/settings/" +
        "user-filters" +
        "/users"
    );
    doc
      .doc(store.state.userID)
      .set(state.filtersRaw)
      .then(() => {});
    dispatch("initPlanBoardFilters");
  },
  updatePlanBoardFiltersMap({ state, dispatch }, filters = {}) {
    if (
      !state.filtersRaw.tasks &&
      !state.filtersRaw.orders &&
      !state.filtersRaw.mapTasks
    ) {
      state.filtersRaw = { ...state.filtersRaw, ...filters };
    }
    if (state.filtersRaw.tasks && filters.tasks) {
      state.filtersRaw.tasks = { ...state.filtersRaw.tasks, ...filters.tasks };
    }
    if (state.filtersRaw.orders && filters.orders) {
      state.filtersRaw.orders = {
        ...state.filtersRaw.orders,
        ...filters.orders,
      };
    }
    if (state.filtersRaw.mapTasks && filters.mapTasks) {
      state.filtersRaw.mapTasks = {
        ...state.filtersRaw.mapTasks,
        ...filters.mapTasks,
      };
    }
    const doc = db.collection(
      "tenants/" +
        store.state.tenantID +
        "/settings/" +
        "user-filters" +
        "/users"
    );
    doc
      .doc(store.state.userID)
      .set(state.filtersRaw)
      .then(() => {});
    dispatch("initPlanBoardFiltersMap");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
