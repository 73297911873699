import firebase from "./firebase";

const storage = firebase.storage();

const dbType = localStorage.getItem("databaseProd") || "prod";
let storagePublic;

switch (dbType) {
  case "prod":
    storagePublic = firebase.app().storage(process.env.PUBLIC_BUCKET_NAME || "gs://yellowq-production-public");
    break;
  case "test":
    storagePublic = firebase.app().storage(process.env.PUBLIC_BUCKET_NAME || "gs://yellowq-test-public");
    break;
  case "dev":
    storagePublic = firebase.app().storage(process.env.PUBLIC_BUCKET_NAME || "gs://yellowq-develop-public");
    break;
  default:
    console.log("No storagePublic connection!");
}

export default storage;
export { storagePublic };
