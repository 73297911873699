import Vue from "vue";

import "./styles/quasar.scss";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import "@quasar/extras/material-icons/material-icons.css";
import iconSet from "quasar/icon-set/fontawesome-v5.js";

import { Quasar, LoadingBar, Notify, Dialog } from "quasar";

Vue.use(Quasar, {
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: {
    LoadingBar, Notify, Dialog
  },
  config: {
    //loadingBar: { /* look at QUASARCONFOPTIONS from the API card (bottom of page) */ }
    loadingBar:  { skipHijack: true }, //+off for json requests
    notify: { /* look at QUASARCONFOPTIONS from the API card (bottom of page) */ },
  },
  iconSet: iconSet,
});
